.radio_button {
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 24px;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  height: 40px;
  line-height: 20px;
  margin-right: 16px;
  padding: 10px 16px;
  background-color: white;
  color: black;
  padding-left: 20px;
}

.element {
  padding: 10px;
  background-color: white;
  color: black;
}

.basicInfocontainer {
  padding: 10px;
  border-style: 1px solid black;
}

.basicHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  flex-direction: row;
}

.inputFormik {
  height: 40px;
  background-color: white;
  color: black;
}

.inputFormikClear {
  padding: 10px;
  background-color: white;
  color: black;
}

.errorMsg {
  color: red;
}

.customerLabel {
  padding-right: 20px;
}

.phone-input {
  .form-control {
    width: 100%;

    &:focus + .flag-dropdown {
      border: 1px solid var(--fill-primary-blue);
      border-right: none;
    }
  }
}

.gstField {
  img {
    cursor: pointer;
  }
  .refresh {
    font-size: 14px;
    font-weight: 700;
    color: var(--fill-primary-blue);
    padding-left: 6px;
    cursor: pointer;
  }
}

.refreshDate {
  font-size: 14px;
  font-weight: 400;
  color: var(--fill-grey-dark2);
  span {
    color: black;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: var(--fill-primary-blue);
  color: var(--fill-primary-blue);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--fill-primary-blue);
  color: var(--fill-primary-blue);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--fill-primary-blue);
  color: var(--fill-primary-blue);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--fill-primary-blue);
  }
  50%,
  100% {
    background-color: var(--fill-light-blue);
  }
}
.ml-3 {
  margin-left: 12px;
}
.ml-8 {
  margin-left: 32px;
}
.mt-10 {
  margin-top: 40px;
}