.manage-users {
  > .header {
    padding: 20px 24px;
    padding-bottom: 0;
    border-bottom: 1px solid var(--fill-grey-light3);

    > .header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + .header-row {
        margin-top: 32px;
      }

      > .col-headers {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        text-transform: uppercase;
        color: var(--fill-grey-medium);
        padding: 10px 0;
        font-size: 12px;
        font-weight: normal;

        > .col-header {
          flex: 1;
        }
      }
    }
  }

  .custom-datagrid-table .react-grid-Main {
    padding: 0;
    .react-grid-Grid {
      border: none;
    }
  }

  .scrollbar-container {
    padding-bottom: 150px;
  }

  .users-list-container {
    background-color: var(--fill-white);

    .user-row {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding: 12px 24px;

      > div {
        flex: 1;
        align-self: center;
      }

      &:hover {
        background-color: var(--fill-grey-light5);
      }
    }

    .profile-avatar {
      margin-right: 16px;
    }

    .profile {
      .name {
        margin-bottom: 6px;
      }
    }

    .contact {
      .email {
        margin-bottom: 6px;
      }
    }
  }
}

.add-edit-user-modal {
  width: 500px;
  > .container {
    border: none;
  }
}

.user-status {
  text-transform: capitalize;
  flex: 1;

  &.active {
    color: var(--fill-success);
  }
  &.inactive {
    color: var(--fill-orangered);
  }
  &.invite-pending {
    color: var(--fill-grey-medium2);
  }
}
