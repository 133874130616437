// @import 'styles';

.select-wrapper {
  display: grid;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  position: relative;

  .label {
    font-size: 0.75rem;
    justify-content: space-between;
    display: flex;
  }

  .select {
    padding: 0.3rem 0.75rem;
    padding-left: 0;
    border-radius: 0.25rem;
    border: 1px solid gray;
    font-size: 1rem;
    width: 100%;
  }

  .error {
    font-size: 0.75rem;
    color: red;
  }
}
.selector {
  border: 1px solid var(--fill-grey-light8);
}