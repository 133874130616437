.settings-customers {
  position: relative;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
}
.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}
.dropdown-select {
  width: 50%;
}
.filter{
  margin-left: -80px;
  z-index: 10;
  padding: 0 15px;
}
.ant-page-header-heading-title{
  font-size: 20px !important;
}

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}