.account-settings__wrapper {
  height: 100%;

  .account-settings {
    height: 100%;
  }

  .int-sidebar {
    a:first-child {
      margin-top: 8px;
    }
  }

  .empty-list-container {
    &--factory-list,
    &--users-list {
      .cta .btn {
        padding: 8px 12px;
        height: 36px;
        display: flex;
        align-items: center;
      }
    }
  }

  .users-sidebar {
    max-width: 200px;
    min-width: auto;

    .nav-name {
      font-size: 14px;
      // color: black;
    }
  }
}

.account-settings__header {
  padding: 1em 1.1em;
}

.account-settings__item {
  padding: 14px 16px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  color: var(--fill-locofast-black);

  &.current_tab {
    background: var(--fill-selection-light);
    color: var(--fill-selection-dark);

    > .nav-logo path,
    > .nav-logo rect {
      stroke: var(--fill-selection-dark);
    }

    &:hover {
      background: var(--fill-selection-light);
    }
  }

  > .nav-name {
    margin-left: 10px;
  }
  > .nav-logo {
    vertical-align: -4px;
  }

  &:hover {
    background: var(--fill-grey-light5);
  }
}

.account-settings__content {
  background-color: var(--fill-white);
  flex-grow: 1;
  // padding: 1em 3em;
  // border-left: 1px solid var(--fill-grey-light2);
  // margin-left: 215px;
  position: relative;

  & > * {
    flex-grow: 1;
  }

  .tab-title {
    font-size: 20px;
    font-weight: 600;
    flex: 1;
  }
}
