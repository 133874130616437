.settings-mills {
  position: relative;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;

  .millsPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

  //TODO -- It should to be removed, once we upgrade the antd liabrary.
  .ant-page-header-heading-extra {
    right: 70px;
  }
}
.dropdown {
  width: 100%;
}
.ant-select-selection-overflow {
  min-height: 40px;
}
.modal-postion {
top: 20px !important;
}
.supplier-modal {
  .ant-modal-content{
    border-radius: 16px;
  }
  .ant-modal-header{
    border-bottom-color: white;
    padding-top: 24px;
    border-radius: 16px 16px 0px 0px;
  }
  .ant-modal-close-x{
    padding-top: 6px;
  }
  .ant-modal-body{
    padding-top: 18px;
    padding-bottom: 12px;
  }
  .ant-modal-footer{
    border-top-color: white;
    padding-bottom: 26px;
  }
  .ant-modal-title{
    font-size: 20px;
    font-weight: 700;
  }
}
.clear {
  width: 183px;
  height: 40px !important;
  font-weight: 700 !important;
  color: var(--fill-primary-blue) !important;
  border: none !important;
}
.apply {
  width: 183px;
  height: 40px !important;
  background-color: var(--fill-primary-blue) !important;
  border-radius: 8px !important;
  color: white !important;
}
.activeOrders {
  margin-left: 20px;
}
.row {
  margin-bottom: 22px;
  .title {
    font-size: 13px;
    color: var(--fill-neutral-gray);
  }
}
.checkbox-group {
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  span {
    padding: 10px 12px !important;
    margin-right: 4px !important;
    font-weight: normal !important;
    white-space: nowrap !important;
  }

  input[type='checkbox']:checked + span:before,
  input[type='radio']:checked + span:before {
    top: 12px !important;
  }
  .radioButton,
  .checkboxButton {
    input[type='radio'],
    input[type='checkbox'] {
      display: none;
    }

    input[type='radio'] + span,
    input[type='checkbox'] + span {
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      color: var(--fill-neutral-gray);
      display: block;
      font-size: 14px;
      font-weight: normal;
      height: auto;
      margin-right: 6px !important;
      padding: 10px 12px !important;
      white-space: nowrap;
    }

    input[type='radio']:checked + span,
    input[type='checkbox']:checked + span {
      background: #f2f8ff;
      color: #0067e2;
      font-weight: 600 !important;
      padding-left: 28px !important;
      position: relative;
      border: none;

      &::before {
        background: url('../../../assets/images/CheckBlue.svg');
        content: '';
        height: 12px;
        left: 10px;
        position: absolute;
        top: 14px;
        width: 12px;
      }
    }

    input[type='radio']:disabled + span,
    input[type='checkbox']:disabled + span {
      opacity: 0.5;
    }

    &.withoutTick {
      input[type='radio']:checked + span,
      input[type='checkbox']:checked + span {
        padding-left: 12px;

        &::before {
          content: '';
          height: 0;
          width: 0;
        }
      }
    }
  }
}
.disabledButton {
  opacity: 0.4;
}