@mixin fix-width($size) {
  min-width: $size;
  max-width: $size;
}

@mixin fix-height($size) {
  min-height: $size;
  max-height: $size;
}

@mixin fix-size($size) {
  @include fix-width($size);
  @include fix-height($size);
}
