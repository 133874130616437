$activity-column-width: 160px;

.relative {
  position: relative;
}

.manage-lfusers {
  .lfusers-header {
    padding-left: 24px;
    .tab-title {
      text-align: left;
    }
    .filter {
      display: flex;
      align-items: center;
      margin: 0 10px;

      &__toggle {
        user-select: none;
        cursor: pointer;
        position: relative;
        border: solid 1px var(--fill-grey-light2);
        border-radius: 4px;
        padding: 6px 12px;
        height: 36px;
        margin-right: 24px;

        display: flex;
        align-items: center;
      }

      &__label {
        font-size: 12px;
        color: var(--fill-grey-dark);
      }

      &__input {
        cursor: pointer;
        border: none;
        background-color: transparent;
        font-weight: 600;
        color: var(--fill-grey-dark);
        padding-left: 8px;
        max-width: 120px;
        text-overflow: ellipsis;
      }
    }
  }

  .select-pane {
    width: 200px;
    top: 42px;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 1, 38, 0.5);
    background-color: var(--fill-white);
    position: absolute;
    width: 100%;
    z-index: 10000;
    transform: translate(-12px,1px);
  }
  .collection-collab__label_left_aigned {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
  }
  .add-user-btn {
    height: 36px;
    padding: 8px 12px;
  }

  .lfusers-content {
    height: 100%;
    overflow: auto;
    padding-bottom: 120px;

    &__container {
      width: 100%;
      border-collapse: collapse;
    }

    &__header {
      padding: 12px 24px;
      font-weight: 600;
      font-size: 12px;
      color: var(--fill-grey-medium);
      text-transform: uppercase;
      border-bottom: 1px solid var(--fill-grey-light5);

      tr th {
        text-align: left;
        padding: 12px 24px;
        // max-width: 320px;
        &:nth-child(1) {
          width: 30%;
        }
      }

      .activity {
        @media screen and (max-width: 1365px) {
          min-width: $activity-column-width;
        }
      }
    }

    &__item {
      align-items: center;
      padding: 12px 24px;
      border-bottom: 1px solid var(--fill-grey-light7);

      .overlay-effect {
        &:first-child,
        &:nth-child(2) {
          &:before {
            content: "";
            width: 100%;
            height: calc(100% - 8px);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 4px;
            z-index: 0;
            background: var(--fill-blue-light3);
            border-radius: 8px;
          }
        }
        &:first-child:before {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:nth-child(2):before {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      td {
        padding: 12px 24px;
      }

      .profile-avatar {
        box-shadow: none;
      }

      .visible-on-hover {
        display: block;
      }
    }

    &__edit {
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 1;
      display: flex;
      height: auto;
      background: var(--fill-white);
      border: 1px solid var(--fill-grey-light2);
      padding: 8px 16px 8px 8px;
      box-shadow: none;

      display: flex;
      border-radius: 8px;
      img {
        padding-right: 8px;
        transform: scale(0.8);
      }
    }

    &__add-members {
      font-weight: 400;
      color: var(--fill-grey-dark);
      height: 36px;
      padding: 8px;
      border-radius: 8px;
      justify-self: flex-start;
      width: 134px;
    }

    p {
      margin: 0;

      &:first-child {
        margin-bottom: 4px;
      }
    }
  }

  .active-row{
    background: red;
  }

  .ant-popover {
    top: 200px;
    width: 154px;
    &-arrow {
      display: none;
    }
    &-inner-content {
      padding-top: 21px;
      padding-bottom: 21px;
    }
  }

  .role-content {
    &-item {
      display: flex;
      align-items: center;
      padding: 0;
      background: none;
      border: 0;
      width: 100%;
      color: var(--fill-grey-dark);
      cursor: pointer;

      p {
        margin-left: 10px;
      }
    }

    .deactivate-item {
      margin-top: 26px;
    }
  }

  .role-row {
    display: flex;
    align-items: center;
    img {
      margin-left: 4px;
    }

    p {
      margin-bottom: 0 !important;
    }
  }
}

.manage-lfusers__title {
  padding-bottom: 1em;
  padding-top: 1em;
}

.top-bar {
  padding: 16px 32px;
}

.img-container {
  width: 42px;
  height: 42px;
  margin-right: 20px;

  div {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.email-content {
  z-index: 1;
}

.user {
  &__profile {
    &-row {
      z-index: 1;
      display: flex;
      align-items: center;
      margin: 4px 0;
    }

    &_image {
      text-transform: uppercase;
      color: var(--fill-white);
      text-shadow: 1px 0px 5px var(--fill-grey-light2);
    }
  }

  &__contacts {
    display: grid;

    p {
      margin: 0;
      color: var(--fill-grey-medium);
    }
  }

  &__activity {
    // display: grid;
    align-content: center;
    @media screen and (max-width: 1365px) {
      width: 130px;
    }

    div {
      position: relative;
    }
  }
}

.email-td {
  @media screen and (max-width: 1365px) {
    width: 162px;
  }
}

.role-td {
  @media screen and (max-width: 1365px) {
    min-width: 220px;
  }
}

.p-grey {
  color: var(--fill-grey-medium);
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.modal__footer-lfusers {
  padding: 16px 28px 22px 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
