$avatar-size: 40px;

.profile-avatar {
  user-select: none;

  > .img {
    background-position: center;
    background-size: cover;
    width: $avatar-size;
    height: $avatar-size;
  }
}

.avatar {
  min-width: $avatar-size;
  max-width: $avatar-size;
  min-height: $avatar-size;
  max-height: $avatar-size;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  > .initials {
    font-size: 14px;
    text-align: center;
    line-height: $avatar-size;
    font-weight: light;
    color: white;
  }
}

.static-color {
  border: 0;
  box-shadow: 0px 0px 2px 1px var(--fill-grey-light2);
}
