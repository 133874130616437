@import "../../../assets/scss/mixins";

.modal__wrapper {
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 1, 38, 0.5);
  background-color: #ffffff;
  min-height: 20vh;
  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &--v2 {
    .modal__footer {
      @extend .modal__footer.filled;
      font-weight: bold;
    }

    .modal__header {
      text-transform: none;
    }
  }

  &.alert-modal {
    max-width: 350px;

    .modal__header{
      margin: 20px;
    }

    .modal__content--text{
      padding: 0 20px 20px;
      color: var(--fill-grey-medium);
    }

    .modal__footer {
      text-align: left;
      border-top: none;
      padding-bottom: 16px;
    }
  }

  .modal__header {
    font-weight: bold;

    &.has-img {
      margin-bottom: 8px;
    }

    &__img {
      margin-bottom: 20px;

      & + div {
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .modal__nav-back {
    @include fix-size(24px);

    margin-right: 16px;
    vertical-align: text-top;
    padding: 0;
  }

  .input-file {
    border-radius: 4px;
    border: 1px solid var(--fill-grey-light3);
  }
}

// ===== this has been modified for notifications modal =====
.modal__wrapper--right {
  height: 85vh !important;
  max-height: 85vh !important;
  min-width: 25vw;
  z-index: 99999;

  top: 56px;
  left: 97%;
  transform: translate(-100%, 0%) !important;
  right: 0% !important;
  // border-radius: 4px;
  // border-top-right-radius: 4px;
  // border-bottom-right-radius: 4px;

  position: fixed;
}

.modal__wrapper--right-full-height {
  height: 100vh !important;
  max-height: 100vh !important;
  min-width: 25vw;
  z-index: 99999;

  top: 0px;
  left: 100%;
  transform: translate(-100%, 0%) !important;
  right: 0% !important;
  border-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  position: fixed;
}

.modal__header {
  margin: 24px;
  user-select: none;

  text-transform: capitalize;
}

.modal__header--empty {
  margin: 2em;
}

.modal__content {
  padding: 12px 24px;
  padding-bottom: 36px;
  flex-grow: 1;
  max-height: 70vh;
  height: 100%;
  overflow: hidden;

  & > * {
    max-height: inherit !important;
  }
}

.modal__content--text {
  padding: 1em 2em;
  padding-bottom: 36px;
  overflow-y: auto;
}

.modal__footer {
  border-top: 1px solid var(--fill-grey-light3);
  padding: 12px 24px;
  text-align: right;
  align-items: center;
  user-select: none;
  text-transform: uppercase;

  span {
    margin-left: auto;
    cursor: pointer;
  }

  .btn--align-right {
    margin-left: auto;
  }

  button + button {
    margin-left: 16px;
  }
}

.modal__footer.filled {
  padding: 12px 24px;

  span {
    background-color: var(--fill-primary-blue);
    color: white;
    border-radius: 0.5em;
    font-size: 14px;
    line-height: 20px;
    padding: var(--btn-padding);
    min-height: 40px;
  }
}

.modal__close {
  margin-left: auto;
  cursor: pointer;
}
