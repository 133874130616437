.input-address {
  .formik-field {
    &__input {
      .row {
        display: flex;
        > .input {
          flex: 1;
          min-width: 0;
          flex-basis: 33%;
          // flex-grow: 0;

          &:focus {
            outline: none;
            z-index: 1;
          }
        }
      }

      .address {
        &-lines {
          border-radius: 4px 4px 0 0;
          resize: none;
          margin-bottom: -1px;
          height: 115px;
        }

        &-city {
          border-radius: 0 0 0 4px;
        }

        &-state {
          border-radius: 0;
          margin-left: -1px;
          margin-right: -1px;
        }

        &-pincode {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }
}
