.reset-pass__content {
  & > * {
    flex-basis: 30%;
  }
}

.currency-modal {
  min-width: 500px;
  max-width: 500px;
  > .container {
    border: none;
    margin: 16px;
  }
}

.buttton {
  display: flex;
  justify-items: flex-end;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
