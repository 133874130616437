.header__wrapper {
  .header__wrapper--notification {
    .header__logo {
      width: 300px;
      background-color: var(--fill-primary-blue);
      border-radius: 0;
      justify-content: flex-start;
      .header__img {
        margin-left: 20px;
        width: 120px;
      }
    }
  }

  .profile-avatar {
    cursor: pointer;
  }
}

.profile-popover {
  min-width: 198px;
  max-width: 198px;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}

.header__wrapper {
  width: 100%;
  height: 56px;
  max-height: 56px;
  background-color: #ffffff;
}

.header__logo {
  // box-shadow: 2px 5px 6px 0 rgba(0, 35, 38, 0.25);

  width: 72px;
  border-top-right-radius: 20px;
  background-color: var(--fill-primary-blue);
  color: #ffffff;
  justify-content: center;
  padding-top: 8px;

  // * {
  //     font-weight: 900;
  //     font-style: italic;
  // }

  // .logo-1 {
  //     align-self: flex-start;
  // }

  // .logo-2 {
  //     align-self: center;
  // }
  .header__img {
    margin: auto;
    max-width: 36px;
  }
}

.header__text {
  padding: 15px 19px;
  font-weight: bold;
  color: #002326;
  flex-grow: 1;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--fill-grey-light3);

  .title {
    font-size: 20px;
    display: flex;
    font-weight: 600;
    align-items: center;

    &__subheading {
      display: flex;
      align-items: center;
    }

    &__heading {
      color: var(--fill-grey-medium);
    }

    &__heading:only-child {
      color: var(--fill-locofast-black);
    }
  }

  .header_container {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .brand-image {
      position: relative;
      padding: 12px;
      max-width: 96px;

      &::before {
        content: " ";
        background-color: var(--fill-grey-light3);
        width: 1px;
        height: 56px;
        position: absolute;
        top: -12px;
        left: -5px;
      }

      img {
        max-width: 80px;
        max-height: 32px;
      }

      &.avatar {
        margin-right: 10px;
        zoom: 0.8;
        padding: 0;
      }

      .full-name {
        font-family: Volkhov;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        max-height: 32px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
  }

  .search_bar {
    margin: 0 12px;
  }

  .help_icon {
    height: 20px;
    vertical-align: bottom;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 24px;
  }

  .account {
    height: 24px;
    width: 24px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 0px 3px 2px #f2f2f2;
  }

  .notification-container {
    margin-right: 24px;

    img {
      height: 20px;
      cursor: pointer;
      vertical-align: bottom;
    }

    .count-outer-circle {
      width: 20px;
      height: 20px;
      background-color: var(--fill-secondary-dark);
      border-radius: 100px;
      position: absolute;
      top: -12px;
      right: -8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .count-inner-circle {
      color: var(--fill-white);
      text-shadow: 0px 0px 0px var(--fill-white);
      position: relative;
      font-size: 10px;
      text-align: center;
      top: 1px;
    }
  }

  .popover-body {
    .pop-over__content-container {
      min-height: 40px;
      max-height: 40px;
      zoom: 0.8;
    }

    .content {
      height: 48px;
      width: 48px;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0px 0px 3px 2px #f2f2f2;
      padding: 2px;
      border: 1px solid var(--fill-locofast-black);
    }

    .logout-btn {
      padding: 8px 10px;
      text-align: center;
      border: 1px solid var(--fill-grey-light3);
      border-radius: 4px;
      & + .logout-btn {
        margin-top: 8px;
      }

      .logout-text {
        font-weight: normal;
        justify-content: center;
        height: 38px;
        color: var(--fill-locofast-black);
      }
    }
  }
}

.header__right-side {
  margin-left: auto;
}

.header__notifications {
  width: 25px;
  margin: auto;
}

.header__user_details {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  .icon-settings {
    width: 28px;
    height: 28px;
    path {
      stroke: var(--fill-grey-dark);
    }
  }

  .user-data {
    text-align: center;
    font-weight: normal;
    padding: 12px 20px;

    p {
      margin: 0;
    }

    .name {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    .email {
      font-size: 12px;
      line-height: 16px;
      color: var(--fill-grey-medium);
    }
  }
}
