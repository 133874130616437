  .scrollbar_view {
    height: 80%;
    overflow-y: auto;
    .element{
      .ant-input{
        height: 32px;
      }
    }
  }
  .basicHeader {
    background-color: blanchedalmond;
  }