@import '../../../assets/scss/colors.scss';

.page {
  margin: 40px 32px 0 32px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  .headline {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
  }
}

.container {
  height: 187px;
  background: var(--fill-light-gray-bg);
  border-radius: 16px;
  margin-top: 56px;
  padding: 32px;
  .dollertext {
    color: var(--fill-neutral-gray1);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
}

.conversionRate {
  color: var(--fill-primary-blue);
  font-weight: 700;
  font-size: 32px;
}

.lastUpdate {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--fill-grey-dark2);
  .dateFormate {
    font-weight: 700;
  }
}
