.bar__wrapper {
  display: flex;
}

.bar__item {
  display: flex;

  align-items: center;
  justify-content: center;

  padding: 8px 0;

  &_last {
    display: flex;
    align-items: center;
    padding: 8px;

    padding-right: 5;
  }

  &.disabled {
    .bar__label {
      cursor: default;
    }
  }

  & + .bar__item {
    &::before {
      content: " ";
      height: 1px;
      width: 64px;
      background-color: var(--fill-grey-light);
      margin: 0 8px;
    }
  }
}

.bar__label {
  color: var(--fill-grey-medium);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  .check {
    margin-left: -5px;
    vertical-align: -1px;

    * {
      fill: white;
    }
  }

  span.step-num {
    background: var(--fill-grey-light);
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: var(--fill-white);
    margin-right: 8px;
    line-height: 32px;
    text-align: center;
  }
}

.bar__item.active {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  .bar__label {
    color: var(--fill-locofast-black);
    font-weight: bold;
  }

  span {
    background: var(--fill-primary-blue);
  }
}

.bar-active {
  background: var(--fill-success) !important;
}

.bar_top {
}
