.role-change-modal {
  width: 360px;
  min-height: 430px;
  padding: 22px 24px;
  padding-bottom: 56px;

  .modal-content {
    padding-bottom: 26px;
  }

  .modal__header--empty {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    font-size: 16px;
    font-weight: 700;
    border: 0;
    margin: 0;
    padding: 0;
  }

  .close-btn {
    background: none;
    border: 0;
    cursor: pointer;
  }

  .content {
    margin-top: 54px;
  }

  .user {
    display: flex;
    align-items: center;
    margin-left: 8px;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .role {
        padding: 6px 8px;
        background: rgba(#e1e1ea, 0.2);
        border-radius: 16px;
        &-title {
          margin: 0;
          font-size: 12px;
          color: var(--fill-grey-medium);
        }
      }
    }

    &-image {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-name {
      margin: 0;
      margin-left: 10px;
      color: var(--fill-grey-dark);
    }
  }

  .subtitle {
    margin-top: 30px;
    font-size: 14px;
    padding-bottom: 0;
    color: var(--fill-grey-dark);
    &.error {
      color: var(--fill-orangered);
    }
  }

  .role-options-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  .role-option {
    padding: 10px 12px;
    background: var(--white);
    border: 1px solid var(--fill-grey-light3);
    border-radius: 24px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .label {
      color: var(--fill-grey-dark);
      font-size: 14px;
      margin: 0;
      margin-left: 4px;
    }

    &-selected {
      background-color: var(--fill-blue-light3);
      border-color: var(--fill-selection-dark);

      .label {
        color: var(--fill-selection-dark);
      }
    }
  }

  .footer {
    display: flex;
    margin-top: 32px;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8px;
    padding: 8px 16px;

    &.right-align {
      display: flex;
      justify-content: flex-end;
    }

    .navigate-options {
      display: flex;
      align-items: center;
    }

    .back-btn {
      border: 0;
      background: none;
      cursor: pointer;
      padding: 0;
      border-radius: 50%;
      height: 40px;
    }

    .steps {
      display: flex;
      align-items: center;
      margin-left: 12px;
    }

    .step {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      border: 1px solid var(--fill-grey-medium);
      margin-left: 8px;
      cursor: pointer;

      &-done {
        background: var(--fill-selection-dark);
        border-color: var(--fill-selection-dark);
      }

      &-active {
        height: 12px;
        width: 12px;
        border: 2px solid var(--fill-selection-dark);
      }
    }
  }
}
