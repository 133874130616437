.tabs {
  height: 100%;
  max-height: 100%;
  // overflow: hidden;
  width: 100%;
}

.tabs__header {
  background-color: #ffffff;
}

.tabs__navbar {
  padding: 0 32px 12px;
  border-bottom: 1px solid var(--fill-grey-light3);
  // height: 48px;
  div {
    user-select: none;
    cursor: pointer;
  }
}

.tabs__tab {
  padding: 10px 12px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 600;

  span {
    font-weight: 600;
  }

  & + .tabs__tab {
    margin-left: 4px;
  }
}

.tabs__tab:hover:not(.tabs__tab--active) {
  background-color: var(--fill-grey-light5);
}

.tabs__tab--right {
  float: right;
}
.tabs__tab--active {
  // box-shadow: inset 0 -3px 0 0 var(--fill-primary-medium);
  background: var(--fill-blue-light);
  border-radius: 8px;
  color: var(--fill-primary-blue);
  text-shadow: 0 0 1px rgba(0,0,0,0.2);

  span {
    color: var(--fill-primary-blue);
    text-shadow: 0 0 1px rgba(0,0,0,0.2);
  }
}

.tabs__panel {
  // padding: 17px 32px;
  // height: 80%;
  // max-height: 80%;
  height: 100%;
  flex-grow: 1;
  background-color: var(--fill-blue-light2);
  max-width: 100%;
  overflow: auto;

  & > * {
    // max-height: 100%;
    flex-grow: 1;
  }
}
