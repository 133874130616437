.input-grp-toggle-btn > .flex {
  display: flex;
  flex-direction: row;

  // &:hover {
  //   > label {
  //     color: var(--fill-selection-dark);
  //   }
  // }

  > label {
    flex: 1;
    font-size: 14px;
  }

  .input-container {
    margin-right: 14px;
  }

  input {
    display: none;

    & + label {
      display: inline-block;
      height: 12px;
      width: 28px;
      background-color: var(--fill-grey-light);
      border-radius: 6px;
      &::after {
        content: " ";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: var(--fill-grey-medium3);
        margin-top: -4px;
        left: -4px;
        transition: all 100ms;
      }
    }

    &:checked {
      & + label {
        display: inline-block;
        height: 12px;
        width: 28px;
        background-color: var(--fill-selection-light2);
        &::after {
          margin-top: -4px;
          left: 14px;
          background-color: var(--fill-selection-dark);
        }
      }
    }
  }
}
