.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: rgb(255, 255, 255);
}
.topline {
  padding: 10px 10px 0px 10px;
}

.brandimg {
  padding: 0 10px;
}

.header-right-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding: 0.5rem 1rem;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}
.commentBox {
  width: 350px;
  margin-left: 10px;
}