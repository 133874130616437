.partner-settings {
  &-table {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);

    &__head {
      display: grid;
      align-items: center;
      grid-column-gap: 16px;
      padding: 0 44px;
      background-color: white;
      border-bottom: 1px solid var(--fill-grey-light3);

      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      min-height: 40px;
      color: var(--fill-grey-dark);
    }

    &__body {
      background-color: var(--fill-grey-light5);
      padding: 16px 24px;
    }
  }

  &-row {
    margin: 8px 0;
    border: 1px solid var(--fill-grey-light3);
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--fill-blue-light4);

    &:hover {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    }

    &__header {
      display: grid;
      column-gap: 16px;
      align-items: center;
      padding: 12px 20px;
      align-items: center;
      background: var(--fill-white);

      > * {
        display: flex;
        overflow: hidden;
      }

      > div:first-child {
        display: grid;
        grid-template-columns: minmax(100px, 100px) 1fr;
        align-items: center;
        color: var(--fill-grey-dark);
        font-weight: 600;
        column-gap: 16px;

        .brand-image {
          max-height: 40px;

          img {
            max-width: 100px;
            max-height: 40px;
          }
        }
      }

      .cta-edit {
        opacity: 0;
      }

      .cta-expand,
      .cta-edit {
        transition: all 100ms;
      }

      &:hover {
        .cta-edit {
          opacity: 1;
        }
      }
    }

    &--expanded &__header {
      .cta-expand {
        transform: rotate(180deg);
      }
    }

    .pill {
      padding: 4px 16px;
      border-radius: 24px;
      max-height: 32px;

      &--org-Construction {
        background-color: rgba(111, 207, 151, 0.3);
      }

      &--org-Fabric {
        background-color: rgba(45, 156, 219, 0.3);
      }
    }
  }
}
