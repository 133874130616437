.tabs {
  &-nav {
    display: flex;
    border-bottom: 1px solid var(--fill-grey-light3);

    &__item {
      display: inline-block;

      text-align: center;
      position: relative;
      cursor: pointer;
      border: none;
      background: transparent;
      padding: 10px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--fill-grey-medium);

      &--active {
        color: var(--fill-primary-blue);

        &::after {
          content: "";
          position: absolute;
          left: 12px;
          bottom: 0;
          height: 3px;
          right: 12px;
          background: var(--fill-primary-blue);
          border-radius: 3px 3px 0 0;
        }
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
}
