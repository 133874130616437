.element {
    padding: 10px;
}
.basicInfocontainer {
    padding : 10px;
    border-style: 1px solid black;
    .vpa{
        span{
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
        color: var(--fill-primary-blue);
        }
    }
}
.inputFormikClear {
    padding: 10px;
    background-color: white;
    color: black;
}