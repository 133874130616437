.forgot-pwd {
  .wrapper {
    border: 1px solid var(--fill-grey-light2);
    border-radius: 8px;
    width: 412px;

    .navigation {
      border-bottom: 1px solid var(--fill-grey-light2);
      height: 56px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      text-transform: uppercase;
      padding: 16px 24px;

      > img {
        vertical-align: bottom;

        & + span {
          margin-left: 12px;
          font-weight: bold;
          // line-height: 24px;
        }
      }
    }

    .f-w-bold{
      font-weight: 600;
    }

    .content {
      padding: 24px 32px 12px;

      .header {
        text-align: center;
        margin: 24px 16px 32px;
        .heading {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 8px;
          border: none;
        }
        .desc {
          font-size: 14px;
          letter-spacing: 0.2px;
        }
      }

      .btn--large {
        font-weight: bold;
        line-height: 1.43;
        margin-top: 7px;
        margin-bottom: 26px;
      }

      footer {
        display: flex;
        color: var(--fill-grey-medium);
        padding: 12px;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        img {
          padding: 0 8px;
        }
      }
    }
  }
}
