@import "../../../assets/scss/_mixins.scss";

.relative {
  position: relative;
}

.center {
  text-align: center;
}

.avg-quotes-range {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: var(--fill-grey-medium3);
  width: 100%;
}

.fixed-column {
  height: 100%;
}

.ant-table-placeholder {
  z-index: 0;
}

.table-container {
  padding-left: 24px;
  margin-top: 10px;
  margin-bottom: 52px;
  overflow-x: auto;

  thead > tr > th {
    background-color: var(--fill-white);
  }

  thead[class*="ant-table-thead"] th {
    color: var(--fill-grey-medium);
    font-size: 14px;
  }

  .ant-popover-inner-content {
    background-color: var(--fill-blue-light3);
    border-radius: 8px;
  }
}

.column-right-border {
  border-right: 1px solid var(--fill-grey-light3);
}
.column-left-border {
  border-left: 1px solid var(--fill-grey-light3);
}

.row-table {
  position: relative;
  height: 72px;

  &:nth-of-type(even) {
    background: var(--fill-grey-light7);
  }
}

.circled-value {
  border: 1px solid var(--fill-grey-light3);
  border-radius: 50%;
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: var(--fill-grey-medium3);
  margin-left: 4px;
}

.quantity {
  &-booked {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  &-value {
    margin: 0;
  }
  &-circled-value {
    @extend .circled-value;
    position: relative;
    text-align: center;
  }
}

.row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  &-img {
    margin-left: 10px;
    margin-top: -3px;
  }

  &-modal {
  }

  &-item {
    display: flex;
    margin-top: 4px;
    align-items: center;

    p {
      margin-bottom: 0;
      margin-left: 6px;
    }
  }
}

.bottom-bar {
  position: fixed;
  background-color: var(--fill-white);
  border-top: 1px solid var(--fill-grey-light3);
  bottom: 0;
  width: calc(100% - 72px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 32px;
  height: 48px;

  span {
    margin-left: 16px;
    font-size: 12px;
    color: var(--fill-grey-dark);
  }
}

.report-download-btn {
  padding: 4px 8px;
  background-color: var(--fill-blue-light3);
  border-radius: 16px;
  border: none;
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  color: var(--fill-selection-dark);
  cursor: pointer;

  img {
    margin-right: 6px;
  }
}
