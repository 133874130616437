.addUserModal {
  width: 600px;
  padding: 0px 24px;
  // max-height: 432px;

  &__textarea {
    resize: none;
    border-bottom: 0;
    padding: 12px 16px;
    border-radius: 2px 2px 0 0;
    width: 100%;
  }

  &__scroll {
    max-height: 540px;
  }

  &__invisible_checkbox {
    position: absolute;
    right: 20px;
    top: 30px;
    width: 40px;
    opacity: 0;
    height: 30px;
  }

  &__input_grp {
    padding: 10px 0px;
    flex-direction: column;

    & > :first-child {
      padding: 11px 0px;
    }
  }

  &__input_grp_no_label {
    flex: 1;

    input {
      width: 100%;
    }
  }

  &__select {
    width: 250px;
    * {
      height: 40px;
    }
  }

  &__address_group {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    width: 95%;
  }

  :last-child {
    box-shadow: none !important;
  }

  :global .input-file__progress {
    align-self: auto;
  }

  :global .input-file__delete {
    padding-right: 16px;
  }

  :global .input-file__wrapper {
    padding: 6px;
  }

  :global .scrollbar-container {
    max-height: 250px;
    border: 1px solid var(--fill-grey-light4);
    border-top: 0;
    left: -2px;
  }

  &__adjacent {
    overflow: auto;
    padding: 14px 0;

    > div {
      margin: 0 12px;
      padding: 0;
    }
  }

  .column {
    flex-direction: column;
  }

  .field_err {
    color: var(--fill-error);
  }
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.addAddress {
  color: var(--fill-primary-medium);
  line-height: 1.43;
  font-weight: bold;
  margin-bottom: 20px;
}

.remove_address {
  position: absolute;
  top: 10px;
  right: 10px;
}

:global .label_class {
  color: var(--fill-grey-dark);
}
