.formik-textarea {
  width: 100%;

  label {
    color: #333340;
  }

  textarea {
    resize: none;
    line-height: 1.43;
    letter-spacing: 0.2px;
    padding: 12px 16px;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid var(--fill-primary-blue);
    }
  }

  .error {
    color: #d32f2f;
  }
}
