.fiter-dropdown {
  position: relative;
}

.DayPicker_transitionContainer {
  // position: absolute;
  // left: -276px;
  // top: -300px;
}

.sticky-header {
  position: sticky;
  top: 0;
  background: var(--fill-white);
  z-index: 99;
  width: 100%;
  border-bottom: 1px solid var(--fill-grey-light7);
}

.quick-menu {
  &-container {
    margin-top: 32px;
  }

  &-label {
    font-size: 12px;
    color: var(--fill-grey-medium);
    text-transform: uppercase;
  }

  &-btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin-top: 20px;
  }

  &-btn {
    height: 40px;
    text-transform: capitalize;

    &-active {
      color: var(--fill-primary-blue);
      border-color: var(--fill-primary-blue);
    }
  }
}

.filter-heading {
  color: var(--fill-grey-dark);
  font-size: 16px;
}

.filter-dropdown {
  .DateRangePicker_picker {
    top: -281px !important;
    right: 266px !important;
    min-height: 401px;
    padding-top: 52px;
    left: auto !important;
  }
  .DayPicker__withBorder {
    box-shadow: none;
  }
}

.popup-container {
  .ant-popover-inner {
    box-shadow: none;
    border: 1px solid var(--fill-grey-light6);
    border-radius: 0;
  }

  .ant-popover {
    left: auto !important;
    right: 25px !important;
  }

  .DateRangePicker_picker {
    border: 1px solid var(--fill-grey-light6);
    border-right: none;
    border-radius: 0;
  }
  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    right: 19%;
    display: none;
  }
}

.date-range {
  margin-top: 22px;
  &-container {
    margin-top: 38px;
  }
}

.filter-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  .clear-btn {
    flex: 1;
    margin: 0;
    text-align: center;
    color: var(--fill-primary-blue);
    font-weight: 700;
    cursor: pointer;
  }

  .apply-btn {
    background-color: var(--fill-primary-blue);
    color: var(--fill-white);
    font-weight: 700;
    width: 112px;
    border: none;

    &-container {
      flex: 1;
      text-align: center;
    }
  }
}

.calendar-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  img {
    margin-right: 10px;
  }
}
