.org-select-user-modal {
  // min-height: 524px;
  max-height: 524px;
  width: 500px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__content {
    padding: 12px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 461px;
  }

  &__search {
    input {
      --padding-left: 24px;
      padding: 16px 8px;
    }
  }

  &__users {
    &-list {
      flex: 1;
      margin-top: 24px;
      overflow: auto;
      margin-right: -10px;
      padding-right: 10px;
    }

    &-item {
      display: grid;
      grid-template-columns: 32px 1fr;
      column-gap: 16px;
      align-items: center;
      height: 48px;
      cursor: pointer;
      width: 100%;
      text-align: left;
      border: 1px solid transparent;
      border-radius: 8px;

      &,
      * {
        transition: background-color 100ms ease-out, border-color 100ms ease-out;
      }

      & + .org-select-user-modal__users-item {
        margin-top: 8px;
      }

      .profile-avatar {
        background-color: rgba(225, 225, 234, 0.2) !important;
        border: none;
        min-height: 32px;
        min-width: 32px;
        max-height: 32px;
        max-width: 32px;

        > .initials {
          font-weight: 600;
          font-size: 16px;
          color: var(--fill-purple-light3);
        }
      }

      &.selected {
        background-color: var(--fill-blue-light3);
        text-shadow: none;
        font-weight: 600;

        .profile-avatar {
          background-color: var(--fill-purple-light3) !important;

          > .initials {
            color: white;
          }
        }

        .user-name {
          color: var(--fill-primary-blue);
        }
      }

      &:hover {
        background-color: var(--fill-grey-light7);
      }

      &:focus {
        border: 1px solid var(--fill-primary-blue);
      }
    }
  }
}
