input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
}

.error-msg {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}