.modal-footer {
  padding: 18px 32px;
  display: flex;
  justify-content: flex-end;
  background-color: white;

  &__cta {
    > .btn {
      min-width: 124px;
    }
  }

  &__cta + &__cta {
    margin-left: 16px;
  }

  &.bordered {
    border-top: 1px solid var(--fill-grey-light3);
  }
}
