.fields-two {
  display: flex;
  > * {
    flex: 1;
    & + * {
      margin-left: 16px;
    }
  }
}
.input-grp {
  width: 100%;
  margin-bottom: 10px !important;

  label,
  input,
  select {
    display: block;
    width: 100%;
  }

  label {
    text-transform: capitalize;
  }

  select {
    background: url("../../../../assets/images/navigation/ic-arrow-drop-down.svg") no-repeat;
    background-position: right 8px top 10px; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    // padding: 4px 20px;
  }
  select:disabled {
    background-color: rgb(235, 235, 228);
  }

  label {
    margin-left: 1px;
    margin-bottom: 6px;
    color: #333340;
  }

  .input-grp__lower {
    margin-top: 4px;
    padding: 0 12px;
    min-height: 1.2em;
  }

  .input-len-count {
    margin-left: auto;
  }

  .error {
    color: #d32f2f;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="text"]:focus {
    outline: none;
  }
}

.input-grp--error {
  input {
    border: 1px solid #d32f2f !important;
  }
}

.input-container {
  display: flex;
  position: relative;

  &.with-left-addon {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
    .addon {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &.with-right-addon {
    input {
      border: 1px solid var(--fill-grey-light2);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    .addon {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .addon {
    color: var(--fill-grey-medium);
    background: var(--fill-grey-light5);
    padding: 10px 16px;
    border: 1px solid var(--fill-grey-light2);
    white-space: nowrap;
    > * {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.custom-input {
  &__field-box {
    position: relative;

    input {
      border: 1px solid var(--fill-grey-light3);
      padding: 9px 16px;
      border-radius: 4px;
    }

    &.has-prefix {
      --padding-left: 16px;

      input {
        padding-left: calc(var(--padding-left) + var(--prefix-width) + 4px);
      }
      &-glued {
        input {
          border-radius: 0 4px 4px 0;
          padding-left: 16px;
        }
      }
    }

    &.has-suffix {
      --padding-right: 16px;
      input {
        padding-right: calc(16px + var(--suffix-width) + 4px);
      }
      &-glued {
        input {
          border-radius: 4px 0 0 4px;
          padding-right: 16px;
        }
      }
    }

    &.has-prefix,
    &.has-suffix {
      &-glued {
        display: flex;

        input {
          flex: 1;
          font-size: 14px;
          line-height: 20px;
          display: inline-block;
        }
      }
    }
  }

  &__prefix,
  &__suffix {
    color: var(--fill-grey-light);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    &--glue {
      display: flex;
      position: relative;
      top: unset;
      transform: none;
      color: var(--fill-grey-medium);
      background-color: var(--fill-grey-light5);
      padding: 9px 16px;
      border: 1px solid var(--fill-grey-light3);
    }
  }

  &__prefix {
    left: var(--padding-left);

    &--glue {
      border-right: none;
      border-radius: 4px 0 0 4px;
      left: unset;

      & + input {
        border: 1px solid var(--fill-grey-light3);
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__suffix {
    right: var(--padding-right);

    &--glue {
      border-left: none;
      border-radius: 0 4px 4px 0;
      right: unset;
    }
  }
  &.input-grp {
    margin-bottom: 0 !important;
  }
}
