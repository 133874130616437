

.formik_input.horizontal {
  display: flex;
  align-items: center;
}

.formik_input.vertical {
  display: flex;
  flex-direction: column;

  input:disabled {
    background: var(--fill-grey-light6);
  }
}
.formik_input {
  &--hidden {
    display: none!important;
  }
}
