.manage-vendors {
  > .header {
    padding: 20px 24px;
    padding-bottom: 0;
    border-bottom: 1px solid var(--fill-grey-light3);

    .top-level-header {
      display: flex;
    }

    > .header-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 20px;
      margin-top: 32px;

      & + .header-row {
        margin-top: 32px;
        margin-bottom: 16px;
      }

      > .col-headers {
        text-transform: uppercase;
        color: var(--fill-grey-medium);
        padding: 10px 0;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .custom-datagrid-table .react-grid-Main {
    padding: 0;
    .react-grid-Grid {
      border: none;
    }
  }

  .scrollbar-container {
    padding-bottom: 150px;
  }

  .vendors-list-container {
    background-color: var(--fill-white);
    margin: 12px 0;

    .vendor-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 20px;
      align-items: center;
      padding: 16px 24px;

      > div {
        flex: 1;
        align-self: center;
      }

      .with-hover {
        display: none;
      }

      &:hover {
        background-color: var(--fill-grey-light5);

        .with-hover {
          display: block;
        }
      }
    }
  }

  .empty-list-container {
    height: 100%;
  }
}

.add-edit-vendor-modal {
  width: 500px;
  > .container {
    border: none;
  }
}
